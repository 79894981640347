body{
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.contenedor {
    width: 95%;
    margin: 0 auto;
    display: flex;
	margin-top: 20px;
}
.contenedor_botonera {
    width: 85%;
    margin: 0 auto;

}
.botoneras {
	padding: 10px;
	float: right;
}
.contenedor_centrado {
    width: 1560px;
    margin: auto;
}
.contenedor_centrado_dialog {
    width: 100%;
    margin: auto;
}
.contenedor_centrado_pasos {
    margin: auto;
  	float: left;
}
.tarjeta {
    float: left;
    width: 370px;
    padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 20px;
}

.tarjeta_interior {
    overflow: hidden;
    border-radius: 15px;
    background-color: #fff;
}

.pie_tarjeta {
    background-color: rgb(226, 232, 240);
    height: 8rem;
    padding: 8px;
    --tw-bg-opacity: 1;
	border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.foto_tarjeta {
    background-image: url("https://betianapade.com.ar/fotos/cheese_cake.jpg");
    background-position: center;
    height: 15rem;
    background-size: cover;
    --tw-bg-opacity: 1;
}
h3{
	--tw-text-opacity: 1;
	color: rgb(45 55 72 );
	height: 2rem;
	font-size: 1.125rem;
  	font-weight: 600;
  	margin-top: 0;
	margin-right: 0;
	margin-bottom: 1;
	margin-left: 0;
}
.titulo{
	color: rgb(45 55 72 );
	height: 2rem;
	font-size: 1.25rem;
  	font-weight: 600;
  	margin-top: 0;
	margin-right: 0;
	margin-bottom: 1;
	margin-left: 0;
}
.comentario{
	color: rgb(100 21 255);
	font-weight: 700;
	font-size:1rem;
}
.porciones{
	margin-top: 0.25rem;
	font-size: 1.125rem;
	font-weight: 500;
	--tw-text-opacity: 1;
	color: rgb(113 128 150 / var(--tw-text-opacity));
	margin: 0;
}
.carbohidratos{
	margin-top: 0.25rem;
	font-size: 1.125rem;
	font-weight: 700;
}
.tarjeta_pie_textos {
    float: left;
}
.tarjeta_pie_boton {
    float: right;
}
.boton_tarjeta{
	margin: 6px;
	font-size: 0.875rem;
	border-radius: 25px;
	--tw-bg-opacity: 1;
	background-color: rgb(100 21 255);
	padding: 0.75rem;
	font-weight: 700;
	--tw-text-opacity: 1;
	color: rgb(247 250 252);
	border-style: solid;
}
.boton_navegacion{
	height: 50px;
	line-height: 50px;
	width: 50px;
	font-size: 1.50em;
	font-weight: bold;
	border-radius: 50%;
	background-color: rgb(100 21 255);
	color: rgb(247 250 252);
	text-align: center;
	cursor: pointer;
	--tw-bg-opacity: 1;
	--tw-text-opacity: 1;
	border-style: solid;
	margin: 7px;
}

